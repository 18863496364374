<template>
  <form
    inert
    ref="loginFormRef"
    hidden
    role="hidden"
    class="hidden"
    method="post"
    action="/account/login"
    id="customer_login"
    accept-charset="UTF-8"
    novalidate="true"
    autocomplete="off"
  >
    <input type="hidden" name="form_type" value="customer_login" />
    <input type="hidden" name="utf8" value="✓" />
    <input v-if="returnTo?.length" type="hidden" name="return_to" :value="returnTo" />
    <input
      id="CustomerEmail"
      :value="email"
      name="customer[email]"
      type="email"
    />
    <input
      id="CustomerPassword"
      :value="password"
      name="customer[password]"
      type="password"
    />
  </form>
</template>

<script lang="ts" setup>
defineProps<{
  email: string;
  password: string;
  returnTo?: string | string[];
}>();
const loginFormRef = ref<HTMLFormElement>();

defineExpose({
  loginFormRef,
});
</script>